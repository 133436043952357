const menu = [
  // {
  //   text: "Inicio",
  //   action: "/inicio"
  // },
  // {
  //   text: "Cardápio",
  //   action: "/produtos/categoria"
  // },
  {
    text: "Promoçōes e Eventos",
    action: "#promocao-evento"
  },
  {
    text: "Contato",
    action: "#contato"
  },
  // {
  //   text: "Avaliação Rápida",
  //   action: "/avaliacao-rapida"
  // },
  // {
  //   text: "Pesquisa de Satisfação",
  //   action: "/pesquisa-satisfacao"
  // }
];

export default menu;
