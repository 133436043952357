import React, { useState } from 'react';
import {
	Collapse,
	Container,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink
} from 'reactstrap';
import styled from 'styled-components';
import logo from '../logo.png';

const AppbarRoot = styled.div`
	padding-bottom: 50px;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 1;

	.navbar {
		background-color: white;
		height: 120px;
		padding: 16px 0;

		.navbar-collapse {
			background: white;
			margin-top: -15px;
			text-align: center;
		}
	}

	@media screen and (max-width: 768px) {
		& {
			padding-bottom: 16px;
		}
		.navbar {
			height: auto;
			padding: 0;
		}
		.navbar-toggler {
			margin-right: 16px;
		}
	}
`;

const LinkText = styled(NavLink)`
	/* font-weight: 700; */
	color: #444 !important;
	font-size: 24px;
	font-family: 'Londrina Solid';
	padding-left: 20px !important;
	padding-right: 20px !important;

	&:hover {
		color: #222 !important;
	}
`;

const ImageLogo = styled.img`
	max-width: 100%;
	max-height: 90px;

	@media screen and (max-width: 768px) {
		max-height: 60px;
		margin-top: 16px;
		margin-left: 16px;
	}
`;

function Appbar() {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<AppbarRoot>
			<Navbar fixed transparent light expand="md">
				<Container>
					<NavbarBrand href="/">
						<ImageLogo title={'Webgula Menu'} alt={'Webgula Menu'} src={logo} />
					</NavbarBrand>
					<NavbarToggler onClick={() => setIsOpen(!isOpen)} />
					<Collapse isOpen={isOpen} navbar>
						<Nav className="ml-auto" navbar>
							<NavItem>
								<LinkText href={'#sobre'}>Sobre o WebGula</LinkText>
							</NavItem>
							<NavItem>
								<LinkText href={'#como-funciona'}>Como Funciona</LinkText>
							</NavItem>
							<NavItem>
								<LinkText href={'#fale-conosco'}>Fale Conosco</LinkText>
							</NavItem>
						</Nav>
					</Collapse>
				</Container>
			</Navbar>
		</AppbarRoot>
	);
}

export default Appbar;
