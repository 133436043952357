import React from 'react';
import Appbar from './components/Appbar';
import styled from 'styled-components';
import SliderMain from './components/SliderMain';
import Intro from './components/Intro';
import ScrollTop from './components/ScrollTop';
import Footer from './components/Footer';
import Features from './components/Features';

const Body = styled.div`
	background: url('/images/bg.jpg');
	background-repeat: repeat;
	height: 100vh;
	padding-top: 165px;
`;

function LayoutWebGula() {
	return (
		<Body>
			<Appbar />
			<SliderMain />
			<Intro />
			<Features />
			<Footer />
			<ScrollTop />
		</Body>
	);
}

export default LayoutWebGula;
