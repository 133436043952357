import React from "react";  
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import Appbar from "./components/Appbar";
import styled from "styled-components";
import SliderMain from "./components/SliderMain";
import Intro from "./components/Intro";
import ScrollTop from "./components/ScrollTop";
import Footer from "./components/Footer";
import Contato from "./components/Contato";
import InfosSemanal from "./components/InfosSemanal";

const Body = styled.div`
  background: url('/images/bg.jpg');
  background-repeat: repeat;
  height: 100vh;
  padding-top: 165px;
`;

function LayoutDefault() {
  return (
    <Body>
      <Appbar />
      <SliderMain />
      <Intro />
      <InfosSemanal />
      <Contato />
      <Footer />
      <ScrollTop />
    </Body>
  );
}

export default LayoutDefault;
