import React from "react";
import { Route, Switch } from "react-router-dom";
import HomePages from "./HomePages";
import ProdutosPages from "./ProdutosPages";

function NoMatch({ location }) {
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

function Router() {
  return (
    <Switch>
      <Route exact path="/" component={HomePages} />
      <Route path="/produtos" component={ProdutosPages} />
      <Route component={NoMatch} />
    </Switch>
  );
}

export default Router;
