import React, { useState } from "react";
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import styled from "styled-components";

import { LayoutContext } from "../../../../App";

const AppbarRoot = styled.div`
  background: url("/images/line-bg1.png");
  background-repeat: repeat-x;
  background-position: center bottom;
  padding-bottom: 50px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;

  .navbar {
    background-color: white;
    height: 120px;
    padding: 16px 0;
  }
`;

const LinkText = styled(NavLink)`
  font-weight: 500;
  color: #444 !important;
  padding-left: 20px !important;
  padding-right: 20px !important;

  &:hover {
    color: #222 !important;
  }
`;

const ImageLogo = styled.img`
  max-width: 100%;
  max-height: 90px;

  @media screen and (max-width: 768px) {
    max-height: 70px;
    margin-left: 16px;
  }
`;

function Appbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <AppbarRoot>
      <LayoutContext.Consumer>
        {({ layoutData }) => (
          <Navbar fixed transparent light expand="md">
            <Container>
              <NavbarBrand href="/">
                <ImageLogo
                  title={layoutData.nome}
                  alt={layoutData.nome}
                  src={layoutData.logo}
                />
              </NavbarBrand>
              <NavbarToggler onClick={() => setIsOpen(!isOpen)} />
              <Collapse
                style={{ backgroundColor: "#FFFFFF" }}
                isOpen={isOpen}
                navbar
              >
                <Nav className="ml-auto" navbar>
                  {layoutData.menu.map((item, key) => (
                    <NavItem key={key}>
                      <LinkText href={item.action}>{item.text}</LinkText>
                    </NavItem>
                  ))}
                </Nav>
              </Collapse>
            </Container>
          </Navbar>
        )}
      </LayoutContext.Consumer>
    </AppbarRoot>
  );
}

export default Appbar;
