import React from "react";

function CategoriaPage() {
  return (
    <div>
      <h2>Categoria Page</h2>
    </div>
  );
}

export default CategoriaPage;
