import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";

import { LayoutContext } from "../../../../App";

const IntroRoot = styled.div`
  width: 100%;
  clear: both;
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
`;

const H1 = styled.h1`
  color: ${props => props.color};
  font-size: 40px;
  font-family: "Londrina Solid";

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const Time = styled.h1`
  font-size: 18px;
  color: #888;
  font-family: "Londrina Solid";

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const Paragraph = styled.p`
  color: #333;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const ImageIcon = styled.img`
  @media screen and (max-width: 768px) {
    margin: 16px auto;
    display: table;
  }
`;

function Intro() {
  return (
    <IntroRoot>
      <LayoutContext.Consumer>
        {({ layoutData }) => (
          <Container>
            <Row>
              <Col md="6">
                <H1 color={layoutData.primaryColor}>{layoutData.nome}</H1>
                <Paragraph>{layoutData.descricao}</Paragraph>
              </Col>
              <Col md="6">
                <Row>
                  <Col md="6">
                    <Row>
                      <Col md="3">
                        <ImageIcon src={"/images/icon-h4.1.png"} />
                      </Col>
                      <Col md="8">
                        <Time> Tempo médio de entrega</Time>
                        <Paragraph>
                          {layoutData.tempoMedioEntregaNome}
                        </Paragraph>
                      </Col>
                    </Row>
                  </Col>

                  <Col md="6">
                    <Row>
                      <Col md="3">
                        <ImageIcon src={"/images/icon-h4.2.png"} />
                      </Col>
                      <Col md="8">
                        <Time> Contato </Time>
                        <Paragraph>
                          ({layoutData.telefone.slice(0, 2)}){" "}
                          {layoutData.telefone.slice(2)} / {layoutData.email}
                        </Paragraph>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        )}
      </LayoutContext.Consumer>
    </IntroRoot>
  );
}

export default Intro;
