import axios from 'axios';
import { ACCOUNTS_URL } from '../config';

const KEY_AUTH = 'auth-webgula';

class AuthClient {
	async loginClient() {
		let body = {
			grant_type: 'client_credentials',
			audience: 'tacto-webgula-api',
			client_id: 'webgula',
			client_secret: 'secret',
			scope: 'tacto-webgula-api'
		};

		const url = `${ACCOUNTS_URL}/connect/token`;

		body = Object.keys(body)
			.map(key => {
				return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);
			})
			.join('&');

		const response = await axios.post(url, body, {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		});

		this.setData({
			token: response.data.access_token
		});

		return response;
	}

	isLogged() {
		return !!this.getData();
	}

	setData(user) {
		localStorage.setItem(
			KEY_AUTH,
			JSON.stringify({
				...user
			})
		);
	}

	getData() {
		try {
			const result = JSON.parse(localStorage.getItem(KEY_AUTH));

			return result;
		} catch (e) {
			return null;
		}
	}

	getToken() {
		const data = this.getData() || {};

		return data.token;
	}

	checkPermission(chave, permissao) {
		const authData = this.getData();

		if (!authData) return false;

		const permissoes = authData.permissoes || {};

		return !!(permissoes[chave] && permissoes[chave][permissao]);
	}

	logout() {
		localStorage.clear();
	}
}

export default new AuthClient();
