import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import Pages from './ui/pages';
import history from './infra/router/history';
import Layouts from './ui/layouts';
import layoutProvider from './infra/provider/layout-provider';
import PreLoader from './ui/components/PreLoader';
import menu from './infra/router/menu';
import authClient from './infra/auth/AuthClient';

export const LayoutContext = React.createContext({
	layoutData: {}
});

class App extends Component {
	state = {
		isLoading: false,
		layoutData: {
			menu
		}
	};

	componentDidMount = async () => {
		const path = window.location.pathname.split('/');

		let tenant = path[0];

		if (!tenant) {
			tenant = path[1];
		}

		if (!tenant) {
			tenant = 'webgula';
		}

		if (tenant !== 'webgula') {
			this.setState({
				isLoading: true
			});

			await authClient.loginClient();
		}

		try {
			let data = await layoutProvider.getLayoutConfiguration(tenant);

			if (!data) {
				data = {};
				// TODO tratar 404
			}

			this.setState({
				layoutData: {
					...data,
					menu
				}
			});
			console.log('data', data);
		} catch (e) {
		} finally {
			this.setState({
				isLoading: false
			});
		}
	};

	render = () => {
		const { isLoading, layoutData } = this.state;

		return (
			<Router history={history}>
				<LayoutContext.Provider value={{ layoutData: layoutData }}>
					<PreLoader isLoading={isLoading}>
						<Layouts config={{ ...layoutData }}>
							<Pages />
						</Layouts>
					</PreLoader>
				</LayoutContext.Provider>
			</Router>
		);
	};
}

export default App;
