import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';

const IntroRoot = styled.div`
	width: 100%;
	clear: both;
	padding-top: 130px;
	padding-bottom: 80px;
	position: relative;
`;

const H1 = styled.h1`
	color: ${props => props.color};
	font-size: 65px;
	font-family: 'Londrina Solid';
	text-align: center;
	margin-bottom: 100px;

	@media screen and (max-width: 768px) {
		font-size: 45px;
	}
`;

const H2 = styled.h2`
	color: #c33b31;
	font-size: 30px;
	margin-bottom: 30px;
	font-family: 'Londrina Solid';
	text-align: center;
`;

const Paragraph = styled.p`
	color: #333;
	font-size: 18px;
	text-align: center;
`;

function Intro() {
	return (
		<IntroRoot id="sobre">
			<Container>
				<Row>
					<Col md="12">
						<H1>WebGula</H1>
					</Col>
				</Row>

				<Row>
					<Col md="4">
						<H2>Objetivo</H2>
						<Paragraph>
							O Webgula é um serviço criado para deixar o usuário mais Conectado
							ao seu Estabelecimento(Bar, Restaurante e Similares) preferido.
						</Paragraph>
					</Col>

					<Col md="4">
						<H2>Boas Práticas</H2>
						<Paragraph>
							Mantenha seu cadastro sempre atualizado, por questão de segurança
							e para ficar por dentro das funcionalidades e novidades do
							webgula. Sempre que for a um estabelecimento que é parceiro do
							webgula, solicite a um antendente o seu código de acompanhamento
							de conta(QrCode). Ative sua localização em seus dispositivo móvel
							para ter mais precisão na hora de buscar um estabelecimento.
						</Paragraph>
					</Col>

					<Col md="4">
						<H2>Custo</H2>
						<Paragraph>
							O Webgula não tem custo para o consumidor final. Tudo que você
							fizer dentro do aplicativo referente a pagamento é vinculado
							diretamente a um estabelecimento parceiro.
						</Paragraph>
					</Col>
				</Row>
			</Container>
		</IntroRoot>
	);
}

export default Intro;
