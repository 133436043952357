import React from "react";

function HomePage({ history }) {
  return (
    <div>
      <h2>Home Page</h2>
      <button onClick={() => history.push("produtos/categoria")}>
        Categoria
      </button>
    </div>
  );
}

export default HomePage;
