const {
  REACT_APP_BASE_URL_API,
  REACT_APP_ACCOUNTS_URL,
  REACT_APP_BASE_URL_TACTO,
  REACT_APP_GA_KEY
} = process.env;

export const BASE_URL_API =
  REACT_APP_BASE_URL_API || "https://webgula.tactonuvem.com.br";
export const ACCOUNTS_URL =
  REACT_APP_ACCOUNTS_URL || "https://accounts.tactonuvem.com.br";
export const BASE_URL_TACTO =
  REACT_APP_BASE_URL_TACTO || "https://api.tactonuvem.com.br";
export const GA_KEY =
  REACT_APP_GA_KEY || "AIzaSyCQe3MXSxhHANjKCoXIJxv8NeglD6ubuRI";
