import React from "react";
import LayoutDefault from "./default";
import LayoutWebGula from "./webgula";

const layouts = {
  default: LayoutDefault,
  webgula: LayoutWebGula
};

const LayoutProviderComponent = ({ config, children }) => {
  const { layout, ...outerProps } = config;

  if (!layout) return <div />;

  let ComponentLayout = layouts[layout];

  if (!ComponentLayout) {
    ComponentLayout = layouts.default;
  }

  return <ComponentLayout {...outerProps}>{children}</ComponentLayout>;
};

export default LayoutProviderComponent;
