import React from "react";
import { Route, Switch } from "react-router-dom";
import CategoriaPage from "./CategoriaPage";

function Router() {
  return (
    <Switch>
      <Route path="/produtos/categoria" component={CategoriaPage} />
    </Switch>
  );
}

export default Router;
