import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Container,
  Row,
  Col,
  Spinner,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Badge
} from "reactstrap";
import axios from "axios";
import OwlCarousel from "react-owl-carousel2";
import { LayoutContext } from "../../../../App";
import { BASE_URL_API } from "../../../../infra/config";

const InfosSemanalRoot = styled.div`
  width: 100%;
  clear: both;
  padding-top: 150px;
  padding-bottom: 180px;
  position: relative;
`;

const H1 = styled.h1`
  color: ${props => props.color};
  font-size: 40px;
  text-align: center;
  font-family: "Londrina Solid";
`;

function InfosSemanal() {
  return (
    <InfosSemanalRoot id="promocao-evento">
      <LayoutContext.Consumer>
        {({ layoutData }) => (
          <Container>
            <Row>
              <Col md="12">
                <H1 color={layoutData.primaryColor}>Informações da Semana</H1>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <Slides empresaGlobalId={layoutData.id} />
              </Col>
            </Row>
          </Container>
        )}
      </LayoutContext.Consumer>
    </InfosSemanalRoot>
  );
}

function Slides({ empresaGlobalId }) {
  const [infos, setInfos] = useState([]);
  const [loading, setLoading] = useState(false);

  const options = {
    loop: true,
    items: 4,
    nav: false,
    margin: 16,
    autoplay: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 3
      },
      1000: {
        items: 4
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { data } = await axios.get(
        BASE_URL_API +
          "/v1/api/empresas-info-semanal/" +
          empresaGlobalId +
          "/1/lista"
      );

      const items = data.map(item => {
        return {
          title: item.diaSemanaNome,
          caption: item.texto,
          flgMusicaAoVivo: item.flgMusicaAoVivo,
          url: item.imagemUrl || "http://placeimg.com/640/480/any"
        };
      });

      console.log("items", items);

      setLoading(false);

      setInfos(items);
    };

    fetchData();
  }, []);

  return (
    <div style={{ marginTop: 80 }}>
      {loading && (
        <Spinner
          style={{
            width: "3rem",
            display: "table",
            margin: "auto",
            height: "3rem"
          }}
          type="grow"
        />
      )}

      <OwlCarousel options={options}>
        {infos.map(info => (
          <Card>
            <CardImg top width="100%" src={info.url} alt={info.title} />
            <CardBody>
              <CardTitle style={{ fontWeight: "bold" }}>{info.title}</CardTitle>
              <CardSubtitle>
                {info.flgMusicaAoVivo ? (
                  <Badge color="primary">
                    <i class="fa fa-music" aria-hidden="true" /> Música aovivo
                  </Badge>
                ) : null}
              </CardSubtitle>
              <CardText>{info.caption}</CardText>
            </CardBody>
          </Card>
        ))}
      </OwlCarousel>
    </div>
  );
}

export default InfosSemanal;
