import React from "react";
import styled from "styled-components";

const LoaderScreen = styled.div`
  position: fixed;
  z-index: 100500;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;

  .status {
    position: absolute;
    z-index: 1001;
    top: 50%;
    left: 50%;
    display: block;
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
    -webkit-animation: spin 2.5s infinite linear;
    -khtml-animation: spin 2.5s infinite linear;
    -moz-animation: spin 2.5s infinite linear;
    -ms-animation: spin 2.5s infinite linear;
    animation: spin 2.5s infinite linear;
    border: 3px solid transparent;
    border-top-color: #e8500e;
    border-radius: 50%;
  }
  .status::before,
  .status::after {
    position: absolute;
    content: "";
    border-radius: 50%;
  }
  .status::before {
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    -webkit-animation: spin 2s infinite linear;
    -khtml-animation: spin 2s infinite linear;
    -moz-animation: spin 2s infinite linear;
    -ms-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear;
    border: 3px solid transparent;
    border-top-color: #d8bea5;
  }
  .status::after {
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    -webkit-animation: spin 1s infinite linear;
    -khtml-animation: spin 1s infinite linear;
    -moz-animation: spin 1s infinite linear;
    -ms-animation: spin 1s infinite linear;
    animation: spin 1s infinite linear;
    border: 3px solid transparent;
    border-top-color: #4caf50;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const PreLoader = ({ isLoading, children }) => {
  if (isLoading)
    return (
      <React.Fragment>
        <LoaderScreen>
          <div className="status" />
        </LoaderScreen>
      </React.Fragment>
    );

  return <React.Fragment>{children}</React.Fragment>;
};

export default PreLoader;
