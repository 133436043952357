import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';
import logo from '../logo.png';

const FooterRoot = styled.div`
	width: 100%;
	clear: both;
	padding-top: 30px;
	padding-bottom: 0px;
	position: relative;
	background: white;
`;

const Center = styled.div`
	text-align: center;
`;

const Image = styled.img`
	margin: auto;
	display: table;
`;

const FooterWrapper = styled.div`
	text-align: center;
	margin-top: 10px;
	font-size: 0.9em;
	padding: 12px 0;
	background: #f7f7f7;
`;

function Footer() {
	return (
		<FooterRoot id="fale-conosco">
			<Container>
				<Row>
					<Col md="4" />
					<Col md="4">
						<Center>
							<Image src={logo} />
						</Center>
					</Col>
					<Col md="4" />
				</Row>

				<Row style={{ margin: 32 }}>
					<Col md={12} style={{ textAlign: 'center' }}>
						Rua Voluntários da Patria nº 350, Centro Norte - Cuiabá (MT)
					</Col>
					<Col md={12} style={{ textAlign: 'center' }}>
						Equipe WebGula / Tacto Sistemas - (65) 3028-7728 / 3052-6728 -{' '}
						<a href={'mailto:comercial@tacto.com.br'}>comercial@tacto.com.br</a>
					</Col>
				</Row>

				<FooterWrapper>
					© Copyright 2019 by Tacto . Tacto - Mãos certas para cuidar de seu
					negócio.
				</FooterWrapper>
			</Container>
		</FooterRoot>
	);
}

export default Footer;
