import React from "react";
import { Input, FormGroup, Label } from "reactstrap";
import styled from "styled-components";

const Linha = styled.div`
  margin: 0 -16px;
  clear: both;
`;

const Coluna = styled.div`
  float: left;
  width: 30%;
  padding: 8px 16px;

  @media screen and (max-width: 768px) {
    width: 50%;
  }

  @media screen and (max-width: 400px) {
    width: 100%;
  }
`;

function QuestaoObjetiva({ questao, name, value, onChange }) {
  return (
    <Linha>
      {questao.feedBackTipoQuestionarioQtdeMax === 1
        ? questao.feedBackTipoQuestOpcao.map(item => (
            <div>
              <Coluna>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name={name}
                      value={item.valor}
                      onChange={e =>
                        onChange({
                          ...item
                        })
                      }
                    />
                    {item.texto}
                  </Label>
                </FormGroup>
              </Coluna>

              {questao.tipoQuestionarioId === 5 &&
              value &&
              item.texto.indexOf("Sim") > -1 &&
              value.opcaoId === item.id ? (
                <Input
                  placeholder="Qual ?"
                  value={item.respostaTexto}
                  onChange={e => {
                    item.respostaTexto = e.target.value;
                    onChange({
                      ...item
                    });
                  }}
                />
              ) : null}
            </div>
          ))
        : null}
    </Linha>
  );
}

export default QuestaoObjetiva;
