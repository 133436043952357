import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import authClient from './infra/auth/AuthClient';

axios.interceptors.request.use(
	config => {
		const data = authClient.getData() || {};

		if (data.token) {
			config.headers.common['Authorization'] = `Bearer ${data.token}`;

			if (data.chave) {
				config.headers.common['Tacto-Grupo-Empresarial'] = data.chave;
			}

			if (data.empresaBaseId) {
				config.headers.common['EmpresaId'] = data.empresaBaseId;
			}
		}

		return config;
	},
	error => Promise.reject(error)
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
