import React from "react";
import styled from "styled-components";

import { LayoutContext } from "../../../../App";
import { GA_KEY } from "../../../../infra/config";

const ContatoRoot = styled.div`
  width: 100%;
  clear: both;
  position: relative;
`;

//layoutData
function Contato() {
  return (
    <ContatoRoot id="contato">
      <LayoutContext.Consumer>
        {({ layoutData }) => (
          <iframe
            src={`https://www.google.com/maps?q=${
              layoutData.endLatitude
            },${layoutData.endLongitude}&output=embed&key=${GA_KEY}`}
            width={"100%"}
            height={"680"}
            style={{ border: 0 }}
            allowfullscreen
          />
        )}
      </LayoutContext.Consumer>
    </ContatoRoot>
  );
}

export default Contato;
