import React from 'react';
import styled from 'styled-components';
import { Fade } from 'react-slideshow-image';

const slideImages = [
	'https://tactonuvem.blob.core.windows.net/imagens/Webgula/Portal/Banners/Bannerx01.png',
	'https://tactonuvem.blob.core.windows.net/imagens/Webgula/Portal/Banners/Bannerx02.png',
	'https://tactonuvem.blob.core.windows.net/imagens/Webgula/Portal/Banners/Bannerx03.png',
	'https://tactonuvem.blob.core.windows.net/imagens/Webgula/Portal/Banners/Bannerx04.png'
];

const properties = {
	duration: 5000,
	transitionDuration: 500,
	infinite: true,
	indicators: false,
	arrows: false
};

const Slideshow = () => {
	return (
		<Fade {...properties}>
			<div className="each-slide">
				<div style={{ backgroundImage: `url(${slideImages[0]})` }} />
			</div>
			<div className="each-slide">
				<div style={{ backgroundImage: `url(${slideImages[1]})` }} />
			</div>
			<div className="each-slide">
				<div style={{ backgroundImage: `url(${slideImages[2]})` }} />
			</div>
		</Fade>
	);
};

const SliderMainRoot = styled.div`
	width: 100%;
	clear: both;
	margin-top: -50px;
	position: relative;
	z-index: 0;

	.slide-container {
		width: 70%;
		margin: auto;
	}

	h3 {
		text-align: center;
	}

	.each-slide > div {
		display: flex;
		align-items: center;
		justify-content: center;
		background-size: cover;
		height: calc(100vh - 100px);
	}

	.each-slide span {
		padding: 20px;
		font-size: 20px;
		background: #efefef;
		text-align: center;
	}

	.each-fade {
		display: flex;
	}

	.each-fade .image-container {
		width: 75%;
		overflow: hidden;
	}

	.each-fade .image-container img {
		width: 100%;
	}

	.each-fade h2 {
		width: 25%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;
		background: #adceed;
	}
`;

const SliderMainContainer = styled.div`
	position: relative;
	height: calc(100vh - 100px);
`;

class SliderMain extends React.Component {
	state = {
		open: false
	};

	render() {
		return (
			<SliderMainRoot>
				<SliderMainContainer>
					<Slideshow />
				</SliderMainContainer>
			</SliderMainRoot>
		);
	}
}

export default SliderMain;
