import React, { useState } from "react";
import styled from "styled-components";
import { LayoutContext } from "../../../../App";

const ScrollTopStyle = styled.div`
  color: #333;
  background: ${props => props.color};
  width: 50px;
  height: 50px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  border-radius: 4px;
  text-align: center;
  line-height: 50px;
  color: white;
  z-index: 999;
  cursor: pointer;
`;

function ScrollTop() {
  const [showScroll, setShowScroll] = useState(false);

  const height = document.documentElement.clientHeight;

  document.addEventListener(
    "scroll",
    e => {
      if (showScroll && document.documentElement.scrollTop <= height) {
        setShowScroll(false);
      }

      if (!showScroll && document.documentElement.scrollTop > height) {
        setShowScroll(true);
      }
    },
    true
  );

  return (
    <LayoutContext.Consumer>
      {({ layoutData }) => (
        <div>
          {showScroll ? (
            <ScrollTopStyle
              onClick={() => {
                document.documentElement.scrollTop = 0;
              }}
              color={layoutData.primaryColor}
            >
              <i className="fa fa-arrow-up" />
            </ScrollTopStyle>
          ) : null}
        </div>
      )}
    </LayoutContext.Consumer>
  );
}

export default ScrollTop;
