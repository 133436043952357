import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";

import { LayoutContext } from "../../../../App";

const FooterRoot = styled.div`
  width: 100%;
  clear: both;
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
  background: white;
`;

const Paragraph = styled.p`
  color: #333;
  margin-top: 10px;
`;
const Center = styled.div`
  text-align: center;
`;

const Image = styled.img`
  margin: auto;
  display: table;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

function Footer() {
  return (
    <FooterRoot>
      <LayoutContext.Consumer>
        {({ layoutData }) => (
          <Container>
            <Row>
              <Col md="4" />
              <Col md="4">
                <Center>
                  <Image src={layoutData.logo} />
                  <Endereco layoutData={layoutData} />
                </Center>
              </Col>
              <Col md="4" />
            </Row>
          </Container>
        )}
      </LayoutContext.Consumer>
    </FooterRoot>
  );
}

function Endereco({ layoutData }) {
  const endereco = [];

  if (layoutData.endLogradouro) {
    endereco.push(layoutData.endLogradouro);
  }

  if (layoutData.endNumero) {
    endereco.push(layoutData.endNumero);
  }

  if (layoutData.endBairroNome) {
    endereco.push(layoutData.endBairroNome);
  }

  if (layoutData.endCep) {
    endereco.push(layoutData.endCep);
  }

  if (layoutData.endCidadeNome) {
    endereco.push(layoutData.endCidadeNome);
  }

  if (layoutData.endUFNome) {
    endereco.push(layoutData.endUFNome);
  }

  return <Paragraph>{endereco.join(", ")}</Paragraph>;
}

export default Footer;
