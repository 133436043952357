import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';

const IntroRoot = styled.div`
	width: 100%;
	clear: both;
	padding-top: 60px;
	background: #f7f7f7;
	padding-bottom: 50px;
	position: relative;
`;

const H1 = styled.h1`
	color: ${props => props.color};
	font-size: 65px;
	font-family: 'Londrina Solid';
	text-align: center;
	margin-bottom: 100px;

	@media screen and (max-width: 768px) {
		font-size: 45px;
	}
`;

const Paragraph = styled.p`
	color: #333;
	font-size: 18px;
`;

const TextPrimary = styled.span`
	font-weight: bold;
	color: #c33b31;
`;

const FeatureImage = styled.img`
	max-width: 100%;
	display: table;
	margin: auto;
	/* z-index: 1; */
	position: relative;
`;

const ButtonImage = styled.img`
	margin: 16px;
`;

const WrapperImages = styled.div`
	position: relative;
	padding-bottom: 100px;
	/* background: url('/images/mockup.png') no-repeat; */
	background-position: center;
	background-size: 100%;
`;

const WrapperText = styled.div`
	padding-top: 20px;
`;

const WrapperTextItem = styled.div`
	margin-bottom: 20px;
	overflow: hidden;
	clear: both;
`;

const WrapperTextItemIcon = styled.div`
	width: 100px;
	float: left;
	text-align: center;

	i {
		padding-top: 22px;
		background: #fff;
		border-radius: 50%;
		width: 70px;
		height: 70px;
		font-size: 24px;
	}

	@media screen and (max-width: 400px) {
		width: 100% !important;
		margin: 16px 0;
	}
`;

const WrapperTextItemText = styled.div`
	float: right;
	width: calc(100% - 100px);
	padding-left: 16px;

	@media screen and (max-width: 400px) {
		width: 100% !important;
	}
`;

function Features() {
	return (
		<IntroRoot id="como-funciona">
			<Container>
				<Row>
					<Col md="12">
						<H1>Funcionalidades</H1>
					</Col>
				</Row>

				<Row>
					<Col md="6">
						<WrapperImages>
							<FeatureImage src={'/images/mockup-mobile.png'} />
						</WrapperImages>
					</Col>

					<Col md="6">
						<WrapperText>
							<WrapperTextItem>
								<WrapperTextItemIcon>
									<i className="fa fa-file-o" />
								</WrapperTextItemIcon>
								<WrapperTextItemText>
									<Paragraph>
										<TextPrimary>Guia Gastronômico:</TextPrimary> Através de sua
										localização mostramos os estabelecimentos, que são parceiros
										do webgula, mais próximos de você. Com algumas opções de
										filtros.
									</Paragraph>
								</WrapperTextItemText>
							</WrapperTextItem>
							<WrapperTextItem>
								<WrapperTextItemIcon>
									<i className="fa fa-building-o" />
								</WrapperTextItemIcon>
								<WrapperTextItemText>
									<Paragraph>
										<TextPrimary>Cardápio Digital:</TextPrimary> Navegação pelo
										cardápio do Estabelecimento de forma Digital. Obs: A
										Disponibilidade depende da configuração de cada empresa.
									</Paragraph>
								</WrapperTextItemText>
							</WrapperTextItem>
							<WrapperTextItem>
								<WrapperTextItemIcon>
									<i className="fa fa-bookmark-o" />
								</WrapperTextItemIcon>
								<WrapperTextItemText>
									<Paragraph>
										<TextPrimary>Acompanhemto de Conta:</TextPrimary> Sente-se
										na mesa do estabelecimento e solicite ao garçom o seu codigo
										de acompanhamento, assim poderá acompanhar tudo que é
										lançado em sua conta.
									</Paragraph>
								</WrapperTextItemText>
							</WrapperTextItem>
							<WrapperTextItem>
								<WrapperTextItemIcon>
									<i className="fa fa-star-o" />
								</WrapperTextItemIcon>
								<WrapperTextItemText>
									<Paragraph>
										<TextPrimary>Avaliação:</TextPrimary> Envie uma avaliação do
										Estabelecimento que você frequentou, pode ser uma critica,
										uma sugestão ou um elogio.
									</Paragraph>
								</WrapperTextItemText>
							</WrapperTextItem>
							<WrapperTextItem>
								<WrapperTextItemIcon>
									<i className="fa fa-heart-o" />
								</WrapperTextItemIcon>
								<WrapperTextItemText>
									<Paragraph>
										<TextPrimary>Fidelidade:</TextPrimary> Disponibilizamos para
										alguns de nossos parceiros um plano de fidelidade, onde você
										ganha pontos consumindo normalmente e posteriormente poderá
										trocar por prêmios do proprio estabelecimento.
									</Paragraph>
								</WrapperTextItemText>
							</WrapperTextItem>
							<WrapperTextItem>
								<WrapperTextItemIcon>
									<i className="fa fa-shopping-cart" />
								</WrapperTextItemIcon>
								<WrapperTextItemText>
									<Paragraph>
										<TextPrimary>Delivery:</TextPrimary> Ops! Estamos refazendo
										essa funcionalidade para melhor atende-los. Aguardem!
									</Paragraph>
								</WrapperTextItemText>
							</WrapperTextItem>
						</WrapperText>
					</Col>
				</Row>

				<Row>
					<Col md={12} style={{ textAlign: 'center' }}>
						<a
							target="_bank"
							href="https://play.google.com/store/apps/details?id=com.tactonuvem.webgula"
						>
							<ButtonImage src="/images/android-button.png" />
						</a>
						<a
							target="_bank"
							href="https://apps.apple.com/us/app/webgula/id1462601021?l=pt&ls=1"
						>
							<ButtonImage src="/images/apple-button.png" />
						</a>
					</Col>
				</Row>
			</Container>
		</IntroRoot>
	);
}

export default Features;
